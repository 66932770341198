import { createStore } from "vuex";
import axios from "axios";
import i18n from "@/i18n";
import dialcode from "./modules/dialcode";

export default createStore({
  state: {
    ventana: 1,
    visitante: {
      apellidos_nombres: null,
      nombres: null,
      apellido_paterno: null,
      apellido_materno: null,
      fecha_nacimiento: null,
      tipo_documento_visitante_id: null,
      documento_identidad: "",
      empresa: "",
      email: "",
      actividad_a_realizar: "",
      telefono_contacto: "",
      codigo_pais: "",
      empresa_visitante_id: null,
      sitio: "",
    },
    datos_medicos: [],
    datos_medicacion: "",
    datos_alergias: "",
    signos: {
      peso: "",
      talla: "",
    },
    otros_pregunta_formato: null,
    alergias: "",
    documentos_adjuntos: {
      InformeMedicoObesidad: [],
      ReporteLaboratorioObesidad: [],
      RecentSurgeryFiles3: [],
      DisordersFiles1: [],
      DisordersFiles2: [],
      DisordersFiles3: [],
      DiabetesFiles1: [],
      DiabetesFiles2: [],
      DiabetesFiles3: [],
      HypertensionFiles1: [],
      HypertensionFiles2: [],
      PregnancyFiles1: [],
      NeurologicalDisordersFiles1: [],
      NeurologicalDisordersFiles2: [],
      RecentInfectionsFiles1: [],
      RecentInfectionsFiles2: [],
      CardiacProblemsFiles1: [],
      CardiacProblemsFiles2: [],
      CardiacProblemsFiles3: [],
      RespiratoryDisordersFiles1: [],
      RespiratoryDisordersFiles2: [],
      RespiratoryDisordersFiles3: [],
      OphthalmologicalProblemsFiles1: [],
      OphthalmologicalProblemsFiles2: [],
      DigestiveProblemsFiles1: [],
      DigestiveProblemsFiles2: [],
      DigestiveProblemsFiles3: [],
      SleepApneaFiles1: [],
      OtherConditionsFiles: [],
    },
    selectedUnits: null,
    showMedication: false,
    declareNoCoca: false,
    declareNoMarijuana: false,
    empresas: [],
  },
  getters: {},
  mutations: {
    SET_VISITANTE(state, visitante) {
      state.visitante = visitante;
    },
    SET_DATOS_MEDICOS(state, data) {
      state.datos_medicos = data;
    },
    SET_DATOS_MEDICACION(state, data) {
      state.datos_medicacion = data;
    },
    SET_INFORME_MEDICO_OBESIDAD(state, data) {
      state.documentos_adjuntos.InformeMedicoObesidad = data;
    },
    SET_REPORTE_LABORATORIO_OBESIDAD(state, data) {
      state.documentos_adjuntos.ReporteLaboratorioObesidad = data;
    },
    SET_RECENTSURGERYFILES1(state, data) {
      state.documentos_adjuntos.RecentSurgeryFiles1 = data;
    },
    SET_RECENTSURGERYFILES2(state, data) {
      state.documentos_adjuntos.RecentSurgeryFiles2 = data;
    },
    SET_RECENTSURGERYFILES3(state, data) {
      state.documentos_adjuntos.RecentSurgeryFiles3 = data;
    },
    SET_DISORDERSFILES1(state, data) {
      state.documentos_adjuntos.DisordersFiles1 = data;
    },
    SET_DISORDERSFILES2(state, data) {
      state.documentos_adjuntos.DisordersFiles2 = data;
    },
    SET_DISORDERSFILES3(state, data) {
      state.documentos_adjuntos.DisordersFiles3 = data;
    },
    SET_DIABETESFILES1(state, data) {
      state.documentos_adjuntos.DiabetesFiles1 = data;
    },
    SET_DIABETESFILES2(state, data) {
      state.documentos_adjuntos.DiabetesFiles2 = data;
    },
    SET_DIABETESFILES3(state, data) {
      state.documentos_adjuntos.DiabetesFiles3 = data;
    },
    SET_HYPERTENSIONFILES1(state, data) {
      state.documentos_adjuntos.HypertensionFiles1 = data;
    },
    SET_HYPERTENSIONFILES2(state, data) {
      state.documentos_adjuntos.HypertensionFiles2 = data;
    },
    SET_PREGNANCYFILES1(state, data) {
      state.documentos_adjuntos.PregnancyFiles1 = data;
    },
    SET_NEUROLOGICALDISORDERSFILES1(state, data) {
      state.documentos_adjuntos.NeurologicalDisordersFiles1 = data;
    },
    SET_NEUROLOGICALDISORDERSFILES2(state, data) {
      state.documentos_adjuntos.NeurologicalDisordersFiles2 = data;
    },
    SET_RECENTINFECTIONSFILES1(state, data) {
      state.documentos_adjuntos.RecentInfectionsFiles1 = data;
    },
    SET_RECENTINFECTIONSFILES2(state, data) {
      state.documentos_adjuntos.RecentInfectionsFiles2 = data;
    },
    SET_CARDIACPROBLEMSFILES1(state, data) {
      state.documentos_adjuntos.CardiacProblemsFiles1 = data;
    },
    SET_CARDIACPROBLEMSFILES2(state, data) {
      state.documentos_adjuntos.CardiacProblemsFiles2 = data;
    },
    SET_CARDIACPROBLEMSFILES3(state, data) {
      state.documentos_adjuntos.CardiacProblemsFiles3 = data;
    },
    SET_RESPIRATORYDISORDERSFILES1(state, data) {
      state.documentos_adjuntos.RespiratoryDisordersFiles1 = data;
    },
    SET_RESPIRATORYDISORDERSFILES2(state, data) {
      state.documentos_adjuntos.RespiratoryDisordersFiles2 = data;
    },
    SET_RESPIRATORYDISORDERSFILES3(state, data) {
      state.documentos_adjuntos.RespiratoryDisordersFiles3 = data;
    },
    SET_OPHTHALMOLOGICALPROBLEMSFILES1(state, data) {
      state.documentos_adjuntos.OphthalmologicalProblemsFiles1 = data;
    },
    SET_OPHTHALMOLOGICALPROBLEMSFILES2(state, data) {
      state.documentos_adjuntos.OphthalmologicalProblemsFiles2 = data;
    },
    SET_DIGESTIVEPROBLEMSFILES1(state, data) {
      state.documentos_adjuntos.DigestiveProblemsFiles1 = data;
    },
    SET_DIGESTIVEPROBLEMSFILES2(state, data) {
      state.documentos_adjuntos.DigestiveProblemsFiles2 = data;
    },
    SET_DIGESTIVEPROBLEMSFILES3(state, data) {
      state.documentos_adjuntos.DigestiveProblemsFiles3 = data;
    },
    SET_SLEEPAPNEAFILES1(state, data) {
      state.documentos_adjuntos.SleepApneaFiles1 = data;
    },
    SET_OTHERCONDITIONSFILES(state, data) {
      state.documentos_adjuntos.OtherConditionsFiles = data;
    },
    SET_VENTANA(state, ventana) {
      state.ventana = ventana;
    },
    SET_SIGNOS(state, data) {
      state.signos = data;
    },
    SET_OTROS_PREGUNTA_FORMATO(state, data) {
      state.otros_pregunta_formato = data;
    },
    SET_UNITS(state, units) {
      state.selectedUnits = units;
    },
    SET_SHOW_MEDICATION(state, showMedication) {
      state.showMedication = showMedication;
    },
    SET_DECLARE_NO_COCA(state, declareNoCoca) {
      state.declareNoCoca = declareNoCoca;
    },
    SET_DECLARE_NO_MARIJUANA(state, declareNoMarijuana) {
      state.declareNoMarijuana = declareNoMarijuana;
    },
    SET_ALERGIAS(state, alergias) {
      state.alergias = alergias;
    },
    SET_EMPRESAS(state, empresas) {
      state.empresas = empresas;
    },
    RESET_STATE(state) {
      state.ventana = 1;
      state.visitante = {
        apellidos_nombres: "",
        fecha_nacimiento: "",
        tipo_documento_visitante_id: null,
        documento_identidad: "",
        empresa: "",
        email: "",
        actividad_a_realizar: "",
        telefono_contacto: "",
        codigo_pais: "",
      };
      state.datos_medicos = [];
      state.datos_medicacion = "";
      state.datos_alergias = "";
      state.signos = {
        peso: "",
        talla: "",
      };
      state.otros_pregunta_formato = null;
      state.documentos_adjuntos = {
        InformeMedicoObesidad: [],
        ReporteLaboratorioObesidad: [],
        RecentSurgeryFiles1: [],
        RecentSurgeryFiles2: [],
        RecentSurgeryFiles3: [],
        DisordersFiles1: [],
        DisordersFiles2: [],
        DisordersFiles3: [],
        DiabetesFiles1: [],
        DiabetesFiles2: [],
        DiabetesFiles3: [],
        HypertensionFiles1: [],
        HypertensionFiles2: [],
        PregnancyFiles1: [],
        NeurologicalDisordersFiles1: [],
        NeurologicalDisordersFiles2: [],
        RecentInfectionsFiles1: [],
        RecentInfectionsFiles2: [],
        CardiacProblemsFiles1: [],
        CardiacProblemsFiles2: [],
        CardiacProblemsFiles3: [],
        RespiratoryDisordersFiles1: [],
        RespiratoryDisordersFiles2: [],
        RespiratoryDisordersFiles3: [],
        OphthalmologicalProblemsFiles1: [],
        OphthalmologicalProblemsFiles2: [],
        DigestiveProblemsFiles1: [],
        DigestiveProblemsFiles2: [],
        DigestiveProblemsFiles3: [],
        SleepApneaFiles1: [],
        OtherConditionsFiles: [],
      };
      state.selectedUnits = "metric";
      state.showMedication = false;
      state.declareNoCoca = false;
      state.declareNoMarijuana = false;
    },
  },
  actions: {
    guardarDatosPersonales({ commit }, form) {
      commit("SET_VISITANTE", form);
      commit("SET_VENTANA", 3);
    },
    async guardarFormulario({ state, commit }) {
      const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      };

      const getCurrentLocale = () => {
        return i18n.global.locale.value;
      };

      // Reformatear datos_medicos: convertir los valores true a un array de índices
      const reformattedDatosMedicos = state.datos_medicos
        .map((value, index) => (value === true ? index : null))
        .filter((index) => index !== null);

      const data = {
        visitante: state.visitante,
        // Aquí usas el array reformateado
        datos_medicos: reformattedDatosMedicos,
        datos_medicacion: state.datos_medicacion,
        signos: state.signos,
        selectedUnits: state.selectedUnits,
        documentos_adjuntos: {},
        otros_pregunta_formato: state.otros_pregunta_formato,
        alergias: state.alergias,
        locale: getCurrentLocale(), // Añade el idioma actual
      };

      const processDocumentosAdjuntos = async (documentos) => {
        const result = {};
        for (const key in documentos) {
          if (Array.isArray(documentos[key])) {
            result[key] = await Promise.all(
              documentos[key].map((file) => convertToBase64(file))
            );
          } else if (typeof documentos[key] === "object") {
            result[key] = await processDocumentosAdjuntos(documentos[key]);
          }
        }
        return result;
      };

      data.documentos_adjuntos = await processDocumentosAdjuntos(
        state.documentos_adjuntos
      );

      try {
        await axios.post("/api/visitantes", data);
        commit("RESET_STATE");
      } catch (error) {
        // Manejo de error
      }
    },
    async searchCodigos({ state }, dialCode) {
      const country = state.dialcode.codes.find(
        (code) => code.dial_code === dialCode
      );
      return country ? country : null;
    },
    async fetchEmpresas({ commit }) {
      try {
        const { data } = await axios.get(`/api/empresavisitante`);
        commit("SET_EMPRESAS", data);
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    verificarVigencia(_, dni) {
      return axios
        .get(`/api/searchVigencia/${dni}`)
        .then((response) => {
          if (response.data.status === "success") {
            return {
              status: "success",
              message: "Aptitud vigente",
              vigencia: response.data.vigencia, // Puedes devolver más información si es necesario
            };
          } else {
            return {
              status: "error",
              message: "No hay aptitud vigente",
            };
          }
        })
        .catch((error) => {
          console.error("Error en la verificación de vigencia:", error);
          return {
            status: "error",
            message: "Error al verificar el DNI",
          };
        });
    },
    crearRegistroVigente(_, dni) {
      return axios
        .get(`/api/crearRegistroVigente/${dni}`)
        .then((response) => {
          if (response.data.status === "success") {
            return {
              status: "success",
              message: "Aptitud vigente",
              vigencia: response.data.vigencia, // Puedes devolver más información si es necesario
            };
          } else {
            return {
              status: "error",
              message: "No hay aptitud vigente",
            };
          }
        })
        .catch((error) => {
          console.error("Error en la creación del registro vigente:", error);
          return {
            status: "error",
            message: "Error al crear el registro vigente",
          };
        });
    },
  },
  modules: {
    dialcode,
  },
});
